@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .scrollbar-hide::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    .scrollbar-hide {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

@font-face {
  src: url("./fonts/League_Spartan/LeagueSpartan-Regular.ttf");
  font-family: "LeagueSpartan";
}

/* Public Sans */
@font-face {
  src: url("./fonts/Public_Sans/PublicSans-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-family: "PublicSansLight";
}
